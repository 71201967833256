import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
import ContentImg from "../../components/GSTTemp/ContentImg";
import SliderContent from "../../components/GSTTemp/SliderContent";
import Cta from "../../components/Cta";
import ContentForm from "../../components/GSTTemp/ContentForm";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import Package from "../../components/GSTTemp/Package";
// import Hero from "../../components/Hero";

export default function RemoveDirectors() {
  /* Slider */
  // const RemDirSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Removal of Director`,
  //     content: `Best Removal of Director Advisory Service Providers`,
  //     image: "/imgs/registration/ngo/section8.png",
  //     alt_tag: "Best Removal of Director Advisory Service Providers in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Removal Of Directors`,
    buyBtnLink: `#pricing-buy`,
    price: `1999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const RemDirAboutData = {
    header: `Removal of Director`,
    sub_title: `Advisory service for removal of director`,
    content_paragraph: [
      `Public corporation must have at least three directors, and
        every private firm must have at least two directors. If a
        director commits any of the acts of incompetence listed in
        the Act or misses more than 12 months of board meetings, a
        private firm has the power to remove them.The board of a 
        corporation appoints the director as stated in the Companies 
        Act of 2013.`,

      // `The board of a corporation appoints the director,
      //   as stated in the Companies Act of 2013.Depending on the
      //   nature of the business, an organization may place certain
      //   sorts and profiles of directors.`,

      // `It is prohibited from doing so by a court order if it makes
      //   arrangements or agreements in contravention of section 184's
      //   prohibitions, or it will be found guilty of a felony and
      //   given a term of more than six months in jail if it is proven
      //   guilty.`,
    ],
  };

  /* Scroll Nav Data */
  const RemDirScrollId = [
    {
      id: `#qualifications `,
      heading: `Qualifications`,
    },
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#understandings`,
      heading: `Understandings`,
    },
    {
      id: `#information`,
      heading: `Information of Removal`,
    },
  ];

  /* Img Content Component Data */
  const RemDirIcData = {
    id: 'qualifications',
    mt_div: '',
    mt_img: '',
    header: 'Qualifications for Directorship',
    image: '/imgs/registration/shop/shop-est-benefits.png',
    alt_tag: 'Qualifications for Directorship',
    Paragraph: '',
    points: [
      {
        head: `Eligibility`,
        content: `Establishment of nationality`,
        icon: true,
      },
      {
        content: `Demarcation of age`,
        icon: true,
      },
      {
        content: `DIN required`,
        icon: true,
      },
      {
        content: `Maximum number of valid directors`,
        icon: true,
      },
      {
        head: `Ineligibility`,
        content: `When the amount that the buyer must pay the seller increases and the tax invoice's taxable 
        value decreases, the vendor issues a debit note.`,
        icon: true,
      },
      {
        content: `Insane or a bankrupt individual`,
        icon: true,
      },
      {
        content: `Criminal history`,
        icon: true,
      },
      {
        content: `Awaiting delinquent returns`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const RemDirCfData = {
    id: 'procedure',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Procedure for Director Removal',
    content: ``,
    body: [
      {
        head: "Director's resignation in accordance with Section 168",
        points: `The Board members are required to take note of this,
        and the company is required to formally notify the
        Registrar in the manner and by the deadline specified
        the following,`,
        icon: true,
      },
      {
        points: `After the business's general meeting, the company
        shall publish a report of directors containing the
        resignation notice.`,
        icon: true,
      },
      {
        points: `Within a month or 30 days of resigning, the director
        must also recommend and send a copy of the resignation
        to the Registrar along with a detailed letter of
        resignation from the post of Director of the company.`,
        icon: true,
      },
      {
        points: `The time or date the company approved his notice of
        resignation or the specific period stated by the
        company should be the starting point for a director's
        resignation.`,
        icon: true,
      },
      {
        points: `Old directors of the corporation must continue serving
        until new ones are elected or until the company
        nominates new directors at the called general meeting.`,
        icon: true,
      },
      {
        head: 'Remove a director suo-moto by the board',
        points: `All directors will get a 7-day notice before the next
        board meeting. The directors will get a special
        communication in the mail outlining the procedure for
        removing the director.`,
        icon: true,
      },
      {
        points: `Together with the decision to remove the director from
        the firm, the resolution to call an extraordinary
        general meeting will be permitted, pending approval by
        the company's shareholders.`,
        icon: true,
      },
      {
        points: `Removal of the director will be put to a vote by
        the company's members, who will decide if the majority
        vote to remove the member during the general meeting
        held within 21 days.`,
        icon: true,
      },
      {
        points: `Director will have the opportunity to be heard
        before the resolution is authorized.`,
        icon: true,
      },
      {
        points: `Same procedure will be followed after the
        resolution has been approved, and the forms DIR - 11
        and DIR - 12 will be filed along with all necessary
        Board Resolution and Ordinary Resolution documents.`,
        icon: true,
      },
      {
        points: `Director's name will be taken off the Ministry of
        Corporate Affairs' official website after the
        paperwork are submitted.`,
        icon: true,
      },
    ],
  };

  /* Slider */
  const imgSlider = [
    '/imgs/registration/shop/shop-img-slide.png',
    '/imgs/registration/shop/shop-imgslider.png',
    '/imgs/registration/msme/ms-me.png',
  ];

  /*  Slider Content Component Data */
  const RemDirScData = {
    id: 'understandings',
    background: [],
    mt_div: 'mt-3',
    mt_img: 'mt-5',
    header: "Understandings underlying Directors' Resignations",
    paragraph: '',
    content: [
      {
        points: `Argument before the board`,
        icon: true,
      },
      {
        points: `Better career opportunities`,
        icon: true,
      },
      {
        points: `Misuse in business matters`,
        icon: true,
      },
      {
        points: `Suspension because of a violation`,
        icon: true,
      },
      {
        points: `The falloff in nomination`,
        icon: true,
      },
    ],
    images: imgSlider,
  };

  /*  Content Img Component Data */
  const DirRemCiData = {
    id: 'information',
    background: 'main-content',
    mt_div: 'mt-3',
    header: "Information on how you helped remove the company's director",
    paragraph: ``,
    points: [
      {
        content: `A special proclamation for the "removal of the director"
        procedure can be mailed to the firm by stockholders or
        members who possess more than Rs 5,00,000 in shares as
        paid-up capital or who retain more than 1% of the
        entire voting power.`,
        icon: true,
      },
      {
        content: `Shareholders claim to have the authority to choose the
        meeting's date, time, and location. Prior to three
        months following the date of the Board meeting, the
        specific notice shall not be sent. The process of the
        Director's dismissal might be stopped if the board of
        directors and the stockholders formalize their
        intentions.`,
        icon: true,
      },
      {
        head: `In the event that the Company's Director misses
        three consecutive board meetings`,
        content: `In compliance with Section 167 of the Companies Act,
        2013, which stipulates that a Director has resigned
        from office, a Form DIR - 12 will be submitted on his
        behalf, and his name will be struck out from the MCA,
        or Ministry of Corporate Affairs.`,
        icon: true,
      },
      {
        content: `If, despite providing adequate notice for each board
        meeting, he misses more than half of the board
        meetings for a period of 12 months beginning on the
        day of his absence from the first board meeting.`,
        icon: true,
      },
    ],
    image: '/imgs/business/documents-one.png',
    alt_tag: 'Required Documents for GST Return Filling Registration',
  };
  var FAQ_data = [
    {
      header:
        'If all of the directors leave or resign, who will run everything?',
      body: [
        {
          content: `If this is the case, the promoter or impresario will assume
          command. If there is no promoter, the central government will
          provide temporary directors to run the Company's operations until
          new directors are appointed.`,
          icon: false,
        },
      ],
    },

    {
      header: 'Can a Director resign from the Board of Directors?',
      body: [
        {
          content: `Yes, a director can resign from the company.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Should I reapply if I already have a DIN?',
      body: [
        {
          content: `No, a DIN, or 'Director Identification Number,' is assigned for
          life and can thus be used indefinitely.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Can a company keep an NRI or foreigner as a director?',
      body: [
        {
          content: `Yes, it is possible however, they must have a DIN and a valid
          passport. If that person does not have a DIN, they must apply
          through the Company if they want to be a Director of the company.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Is there a specific requirement for the appointment of directors?',
      body: [
        {
          content: `No, there is no specific requirement for the appointment of
          directors.`,
          icon: false,
        },
      ],
    },
    {
      header: " Can 'Director's resignation be rejected?",
      body: [
        {
          content: `According to Section 168 of the Companies Act 2013, the
          administration does not have the authority to reject a director's
          resignation.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How to remove a Director from a Company?',
      body: [
        {
          content: `A company can remove the authority to remove a Director by passing an ordinary resolution 
          that is given to the Director. A board meeting will be conducted by giving notice 
          7 days before all the Directors.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Can a Director be reappointed once he is removed?',
      body: [
        {
          content: `No, a Director who is removed once cannot be appointed as a director again.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Does a Director get compensation even after his removal?',
      body: [
        {
          content: `Even after the Director is removed by the company he is entitled to get the 
          compensation damaged that are payable to him.`,
          icon: false,
        },
      ],
    },
    {
      header: 'On what grounds a Director has to be removed?',
      body: [
        {
          content: `The Office of the Director may happen to be vacated by the statute,
           death, or under the provision of the AOA or the Shareholders agreement.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Removal of Director'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={RemDirSlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={RemDirAboutData} />
          <Counter />
          <ScrollNav scroll_data={RemDirScrollId} />
          <ImgContent item={RemDirIcData} />
          <ContentForm CFSection_data={RemDirCfData} />

          <SliderContent ScSection_data={RemDirScData} />
          <ContentImg CISection_data={DirRemCiData} />
          <Cta />
          <Guidance />

          <FAQAccord items={FAQ_data} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
